@import "./custom/variables";
@import "./custom/dimentions";

@import "node_modules/bootstrap/scss/bootstrap";

@import './custom/bootstrap/card';

@import "./custom/header/header";
@import "./custom/sidebar/sidebar";
@import "./custom/content/content";
@import "./custom/main-container/main-container";

*::-webkit-scrollbar-track {
  -webkit-box-shadow: white;
  background-color: white;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #c8c6c4;
}