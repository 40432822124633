.navbar-brand-btn {
  min-height: 3rem;
  max-height: 3rem;
  min-width: 3rem;
  color: $white;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #002050;
  }
}

.search-box {
  left: 248px;
}
