.main-container {
  height: calc(100vh - 48px);
  overflow-y: hidden;
}

.external-main-container {
  height: calc(100vh);
  overflow-y: hidden;
}

@media(max-width: 991px) {
  .main-container {
    height: auto;
    // overflow-y: auto;
  }
}