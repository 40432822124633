//.content-layout {
//  height: calc(100vh - 183px);
//}
////
//.col-content-layout {
//  height: calc(100vh - 0px);
//}

.h-md {
  height: 400px;
}

.h-sm {
  height: 250px;
}

//
//.col2-content-layout {
//  height: calc(100vh - 230px);
//}

.responsive-content {
  margin-top: 0;
}

@media(max-width: 991px) {
  .responsive-content {
    margin-top: 60px;
  }
}

.bg-light-gray {
  background-color: #f0f0f0;
}

.bg-surface {
  background-color: #f0f0f0;
}