#sidebarMenu {
  background-color: #f3f2f1;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  .__module-container {
    width: 48px;
    background-color: #f3f2f1;
    box-shadow: -3px 0px 10px 0px #d8d8d8 inset;

    .__module-icon {
      &:hover {
        background-color: white;
      }
    }
  }

  .__module-items {
    background-color: #f3f2f1;
    // width: Calc(100% - 48px);
    width: 200px;
  }
}

@media(max-width: 1000px) {
  .__module-items {
    display: none;
  }
}

// @media (max-width: 767.98px) {
//   .sidebar {
//     top: 5rem;
//   }
// }
